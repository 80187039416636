export class UserFacingError extends Error {
  name = "UserFacingError" as const;
  message: string;
  cause?: Error;
  code?: number;

  constructor({
    message,
    cause,
    code,
  }: {
    message: string;
    cause?: Error;
    code?: number;
  }) {
    super(message);
    this.message = message;
    this.cause = cause;
    this.code = code;
  }
}
