import clsx from "clsx";
import type { TwcComponentProps } from "react-twc";
import { twc } from "react-twc";

import { InfoIconTooltipTrigger, Tooltip, TooltipCard } from "./Tooltip";

type LabelProps = {
  htmlFor: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  className?: string;
};

export const Label = ({
  htmlFor,
  tooltip,
  children,
  className = "",
}: LabelProps) => {
  return (
    <label className={clsx("flex gap-1", className)} htmlFor={htmlFor}>
      <span className="text-system-s leading-5 text-grey-80">{children}</span>

      {tooltip && (
        <Tooltip content={tooltip}>
          <InfoIconTooltipTrigger iconClassName="size-3.5" />
        </Tooltip>
      )}
    </label>
  );
};

export const LabelWithTooltipCard = ({
  htmlFor,
  invalid,
  content,
  label,
  allowClicks = true,
}: {
  htmlFor: string;
  invalid?: boolean;
  content: React.ReactNode;
  label: React.ReactNode;
  allowClicks?: boolean;
}) => {
  return (
    <label
      onClick={(event) => (allowClicks ? {} : event.preventDefault())}
      className="mb-3 inline-flex items-center gap-1 text-system-m text-black"
      htmlFor={htmlFor}
      data-invalid={invalid ? "true" : undefined}
    >
      {label}

      <TooltipCard content={content} tooltipSide="top">
        <InfoIconTooltipTrigger className="size-4 text-grey-80" />
      </TooltipCard>
    </label>
  );
};

interface InputProps extends TwcComponentProps<"input"> {
  $invalid?: boolean;
}

export const Input = twc.input<InputProps>((props) => [
  "rounded-lg border-0.5 bg-white h-9 py-0 px-3 shadow-input transition-colors placeholder:text-grey-60 text-black text-system-m focus:outline-none focus:ring-0 tabular-nums",
  props.$invalid
    ? "border-red-60 text-red-60 hover:border-red-60 focus:border-red-60"
    : "border-grey-30 hover:border-grey-40 focus:border-blue-60 data-[invalid]:border-red-60 data-[invalid]:text-red-60 data-[invalid]:hover:border-red-60 data-[invalid]:focus:border-red-60",
]);

export const Select = twc.select`h-9 rounded-lg border-0.5 border-grey-30 bg-white bg-none py-0 text-system-m text-black shadow-input transition-colors placeholder:text-grey-60 hover:border-grey-40 focus:border-blue-60 focus:outline-none focus:ring-0`;

export const Textarea = twc.textarea`w-full resize-y rounded-lg border-0.5 border-grey-30 bg-white text-system-m tabular-nums text-black shadow-input transition-colors placeholder:text-grey-60 hover:border-grey-40 focus:border-blue-60 focus:outline-none focus:ring-0 data-[invalid]:border-red-60 data-[invalid]:text-red-60 data-[invalid]:hover:border-red-60 data-[invalid]:focus:border-red-60`;

export const Message = ({
  children,
  className,
  id,
}: {
  children: React.ReactNode;
  className?: string;
  id: string;
}) => (
  <span
    id={id}
    className={clsx("mt-2 block text-system-s text-danger-500", className)}
  >
    {children}
  </span>
);
