import "./index.css";

import * as Sentry from "@sentry/react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router/dom";

import { sentryBrowserRouter } from "./router";

async function render() {
  const root = ReactDOM.createRoot(document.getElementById("root") as Element);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LD_ID,
    timeout: 1,
    options: {
      baseUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld`,
      eventsUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld-evt`,
      streamUrl: `${import.meta.env.VITE_LD_PROXY_URL}/ld-str`,
      application: {
        id: "conduit-app",
        version: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
      },
      diagnosticOptOut: true,
      streaming: import.meta.env.PROD,
      inspectors: [Sentry.buildLaunchDarklyFlagUsedHandler()],
    },
  });

  root.render(
    <React.StrictMode>
      <LDProvider>
        <RouterProvider router={sentryBrowserRouter} />
      </LDProvider>
    </React.StrictMode>,
  );
}

render();
