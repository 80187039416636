import type { FieldApi } from "@tanstack/react-form";
import { hasAtLeast } from "remeda";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FieldInfo({ field }: { field: FieldApi<any, any, any, any> }) {
  if (
    field.state.meta.isTouched &&
    field.state.meta.errors.length &&
    hasAtLeast(field.state.meta.errors, 1)
  ) {
    return (
      <span
        className="mt-2 block text-system-s text-danger-500"
        id={`${field.name}-message`}
      >
        {field.state.meta.errors.join(", ")}
      </span>
    );
  }

  return null;
}
