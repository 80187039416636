import { Outlet, useParams } from "react-router";

import type { UserFacingError } from "../../../apis/user-facing-error";
import { useGetRollup } from "../../../hooks/api.rollup";
import { NotFoundPanel } from "../../../shared/NotFoundPanels";

export default function ViewNetworkLayout() {
  const { id } = useParams();

  const { data, error } = useGetRollup(id, { retry: false });

  if (error) {
    if (
      (error as UserFacingError)?.code === 404 ||
      (error as UserFacingError)?.code === 400 ||
      (error as UserFacingError)?.message.includes("network not found")
    ) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center border-t-0.5">
          <NotFoundPanel />
        </div>
      );
    }

    /**
     * Don't throw the error if we have data, just retain the page's state
     */
    if (!data) throw error;
  }

  return <Outlet />;
}
