import type { User as FirebaseUser } from "firebase/auth";
import { useEffect } from "react";

import { conduitAPI } from "../apis/conduit-api";
import { logOut, onAuthStateChanged } from "../lib/firebase";
import { resetAuthStoreWithoutUser, useAuthStore } from "../stores/auth-store";
import { getCurrentOrganization } from "../utils/getCurrentOrganization";
import { getNameFromUserObject } from "../utils/getNameFromUserObject";
import useAssignContexts from "./use-assign-contexts";
import { useEvent } from "./use-event";

type AuthStateChangedFunc = (user: FirebaseUser | null) => Promise<void>;

export function useAuth() {
  const assignContexts = useAssignContexts();

  const authStateChanged = useEvent<AuthStateChangedFunc>(async (authUser) => {
    useAuthStore.setState({ user: authUser });

    if (authUser) {
      try {
        const userJson = await conduitAPI.loadUser();

        useAuthStore.setState({
          email: userJson.user.email,
          name: getNameFromUserObject(userJson),
          organization: getCurrentOrganization(userJson.organizations),
          organizations: userJson.organizations,
          userObject: userJson,
        });

        assignContexts(userJson);
      } catch (err) {
        console.error(err);

        await logOut();
      }
    } else {
      resetAuthStoreWithoutUser();
    }
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authStateChanged);

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
