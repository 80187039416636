import { ConduitTerminalUrl } from "../../../constants/urls";
import { Button } from "../../../shared/Button";
import { SuggestedCardItemDescription } from "../../../shared/nodes-get-started-card";
import { setShowHubLink } from "../../../stores/preferences-store";

export default function BrowseRollupsMoved() {
  return (
    <div className="mt-10 flex flex-1 items-center justify-center px-5 pb-5 lg:px-10 lg:pb-10">
      <div className="max-w-sm divide-y-0.5 rounded-xl border-0.5 bg-white shadow-card">
        <div className="px-6 py-4">
          <h2 className="mb-1 text-system-xl text-black">
            Browse rollups has moved
          </h2>

          <p className="text-system-m text-grey-80">
            Conduit rollups can now be discovered on the Conduit Hub.
          </p>
        </div>

        <div className="p-6 pt-5">
          <div className="space-y-4">
            <SuggestedCardItemDescription
              number={1}
              text="New site dedicated to exploring rollups"
            />

            <SuggestedCardItemDescription
              number={2}
              text="Testnet filters and faster search"
            />

            <SuggestedCardItemDescription
              number={3}
              text="More rollups to explore"
            />
          </div>

          <Button
            asChild
            onClick={() => setShowHubLink(true)}
            className="mt-5 w-full"
          >
            <a href={ConduitTerminalUrl} target="_blank">
              Visit Conduit Hub
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
