import type { Organization } from "../apis/api-types";
import { CURRENT_ORGANIZATION } from "../constants/local-storage";

export function getCurrentOrganization(organizations: Organization[]) {
  let organization: Organization | Record<string, never> = {};

  if (organizations.length > 0) {
    let setOrg = false;
    let storedOrgID = null;

    try {
      storedOrgID = window.localStorage.getItem(CURRENT_ORGANIZATION);
    } catch (err) {
      console.error(err);
    }

    if (storedOrgID) {
      organizations.forEach((org) => {
        if (org.organization === storedOrgID) {
          organization = org;
          setOrg = true;
        }
      });
    }

    if (!setOrg) {
      organization = organizations[0];
    }
  }

  return organization;
}
