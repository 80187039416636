import { toast } from "sonner";
import { assertError } from "ts-extras";

export function handleError(
  err: unknown,
  id?: string | number,
): asserts err is Error {
  assertError(err);

  const message = err?.message || "Something went wrong";

  toast.error(message, { id });
}
