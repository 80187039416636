import { InformationCircleIcon } from "@heroicons/react/24/outline";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { forwardRef, type ReactNode, useMemo } from "react";

type InfoIconTooltipTriggerProps = {
  className?: string;
  iconClassName?: string;
  strokeWidth?: number;
};

export const InfoIconTooltipTrigger = forwardRef<
  HTMLButtonElement,
  InfoIconTooltipTriggerProps
>(({ className = "", iconClassName = "", strokeWidth, ...rest }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className={clsx(
        "inline-flex size-5 cursor-help items-center justify-center rounded-full text-grey-80",
        className,
      )}
      {...rest}
      onClick={(e) => e.preventDefault()}
    >
      <span className="sr-only">Show Tooltip</span>

      <InformationCircleIcon
        aria-hidden="true"
        className={clsx("size-4 text-current", iconClassName)}
        strokeWidth={strokeWidth}
      />
    </button>
  );
});

InfoIconTooltipTrigger.displayName = "InfoIconTooltipTrigger";

export function Tooltip({
  children,
  content,
  className = "",
}: {
  children: ReactNode;
  content: ReactNode | undefined;
  className?: string;
}) {
  if (content) {
    return (
      <TooltipPrimitive.Provider delayDuration={300} skipDelayDuration={150}>
        <TooltipPrimitive.Root>
          <TooltipPrimitive.Trigger asChild>
            {children}
          </TooltipPrimitive.Trigger>

          <TooltipPrimitive.Portal>
            <TooltipPrimitive.Content
              onPointerDownOutside={(e) => e.preventDefault()}
              className={clsx(
                "relative z-50 max-w-xs select-none rounded-lg bg-grey-100 px-3 py-2 text-system-m/5 text-white shadow-[0px_4px_12px_0px_rgba(0,_0,_0,_0.25)] will-change-[transform,opacity]",
                [
                  "data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade",
                  "data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade",
                  "data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade",
                  "data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade",
                ],
                className,
              )}
              sideOffset={6}
            >
              {content}
            </TooltipPrimitive.Content>
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  }

  return <>{children}</>;
}

export function TooltipCard({
  children,
  content,
  sideOffset = 6,
  tooltipSide = "bottom",
}: {
  children: ReactNode;
  content?: ReactNode;
  sideOffset?: number;
  tooltipSide?: "top" | "bottom";
}) {
  const isShortViewport = useMemo(
    () => document.documentElement.clientHeight <= 640,
    [],
  );

  if (content) {
    return (
      <TooltipPrimitive.Provider delayDuration={500} skipDelayDuration={350}>
        <TooltipPrimitive.Root>
          <TooltipPrimitive.Trigger asChild>
            {children}
          </TooltipPrimitive.Trigger>

          <TooltipPrimitive.Portal>
            <TooltipPrimitive.Content
              collisionPadding={{ bottom: 8, top: 8, right: 8, left: 8 }}
              side={isShortViewport ? "right" : tooltipSide}
              onPointerDownOutside={(e) => e.preventDefault()}
              className="relative z-50 max-w-xs select-none rounded-lg border-0.5 bg-white px-5 py-4 text-system-m shadow-switcher will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
              sideOffset={sideOffset}
            >
              {content}
            </TooltipPrimitive.Content>
          </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  }

  return children;
}
