export const DocsUrl = "https://docs.conduit.xyz";

export const ScheduleDemoURLNoRedirect =
  "https://conduitxyz.typeform.com/request-demo";

export const EnterpriseRPCPlanContactForm =
  "https://conduitxyz.typeform.com/to/M6F9fzo1";

export const PylonSupportForm =
  "https://portal.usepylon.com/conduit-xyz/forms/support-request";

export const RollupVerificationForm =
  "https://conduitxyz.typeform.com/to/UYTp17YT";

export const JobsURL = "https://jobs.ashbyhq.com/Conduit";

export const EmailAuthURL: string =
  import.meta.env.VITE_EMAIL_REDIRECT_URL ||
  "https://app.conduit-stg.xyz/email-signin-completion";

export const ApiHostUrl: string =
  import.meta.env.VITE_API_HOST ||
  `${window.location.protocol}${window.location.hostname}`;

export const ArbitrumExternalNodeDocsUrl =
  "https://docs.conduit.xyz/guides/run-a-node/arbitrum-node";

export const OptimismExternalNodeDocsUrl =
  "https://docs.conduit.xyz/guides/run-a-node/op-stack-node";

export const ConduitTerminalUrl =
  import.meta.env.VITE_CONDUIT_TERMINAL_URL ||
  "https://terminal.conduit-stg.xyz";

export const MarketplaceUrl = "https://www.conduit.xyz/marketplace";
