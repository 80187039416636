import { Outlet } from "react-router";

import AppSwitcher from "../shared/AppSwitcher";
import { AppNav, AppTopLevelNav } from "../shared/nav";
import { AppFooter } from "../shared/nav-footer";

export default function BaseLayout() {
  return (
    <>
      <AppTopLevelNav className="bg-white" slot={<AppSwitcher />} />

      <AppNav />

      <Outlet />

      <AppFooter />
    </>
  );
}
