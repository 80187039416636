import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { motion } from "motion/react";
import type React from "react";
import { useMemo } from "react";
import { Link, NavLink, type NavLinkProps, useParams } from "react-router";

import { PrestartJob_Status, PrestartJob_Type } from "../apis/api-types";
import { ConduitTerminalUrl, MarketplaceUrl } from "../constants/urls";
import { useGetRollup } from "../hooks/api.rollup";
import { pb } from "../lib/pb";
import { useShowHubLink } from "../stores/preferences-store";
import { cn } from "../utils/twx";
import ConduitLogomark from "./ConduitLogo";
import { UserDropdownManager } from "./UserDropDown";

interface AppNavLinkTabProps extends NavLinkProps {
  layoutPrefix?: string;
  disabled?: boolean;
}

const AppNavLinkTab = ({
  className,
  children,
  layoutPrefix = "top",
  disabled,
  ...rest
}: AppNavLinkTabProps) => {
  return (
    <NavLink
      className={({ isPending, isActive }) =>
        clsx(
          "navLink",
          { active: isActive, pending: isPending, disabled: disabled },
          className,
        )
      }
      {...rest}
    >
      {({ isActive }) => (
        <>
          {children}

          {isActive ? (
            <motion.div
              layoutId={`${layoutPrefix}-appNavLinkTab`}
              className="absolute inset-x-0 bottom-0 h-0.5 w-full rounded-t-full bg-black"
            />
          ) : undefined}
        </>
      )}
    </NavLink>
  );
};

const AppNavExternalLinkTab = ({
  children,
  target = "_blank",
  ...props
}: React.ComponentPropsWithoutRef<"a">) => {
  return (
    <a className="navLink pr-5" target={target} {...props}>
      {children}
      <ArrowUpRightIcon className="absolute right-0 top-[calc(50%-0.5rem)] mt-px size-4 shrink-0 -translate-y-1/2" />
    </a>
  );
};

export function AppTopLevelNav({
  className = "",
  slot,
}: {
  className?: string;
  slot?: React.ReactNode;
}) {
  return (
    <div className={cn("shrink-0 px-6 pb-5 pt-5", className)}>
      <nav className="flex items-center gap-6" role="navigation">
        <Link
          to={pb.deployments()}
          className="size-8 transition-opacity hover:opacity-80 focus:opacity-80 focus:outline-none"
        >
          <span className="sr-only">Back to Home</span>
          <ConduitLogomark />
        </Link>

        {slot}

        <div className="ml-auto">
          <UserDropdownManager />
        </div>
      </nav>
    </div>
  );
}

export function AppNav() {
  const showHubLink = useShowHubLink();

  const { id } = useParams<{ id: string | undefined }>();

  const { data } = useGetRollup(id, { retry: false });

  const networkIsDeploying = useMemo(() => {
    if (data) {
      return data.prestartJobs.some(
        (job) =>
          job.type === PrestartJob_Type.STANDARD &&
          job.status === PrestartJob_Status.RUNNING,
      );
    }

    return false;
  }, [data]);

  return (
    <div className="shrink-0 overflow-scroll border-b-0.5 bg-white px-6 pt-3 sm:overflow-hidden">
      <nav className="flex gap-5" role="navigation">
        {id ? (
          <>
            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkOverview"
              to={pb.dashboardOverview({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Overview</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkMetrics"
              to={pb.dashboardMetrics({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Metrics</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkContracts"
              to={pb.dashboardContracts({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Contracts</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkIntegrations"
              to={pb.dashboardMarketplace({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Apps</span>
            </AppNavLinkTab>

            <AppNavLinkTab
              layoutPrefix="dashboardNav"
              test-id="NetworkSettings"
              to={pb.dashboardSettings({ slugOrId: id })}
              onClick={(e) =>
                networkIsDeploying ? e.preventDefault() : undefined
              }
              disabled={networkIsDeploying}
            >
              <span>Settings</span>
            </AppNavLinkTab>
          </>
        ) : (
          <>
            <AppNavLinkTab layoutPrefix="appNav" to={pb.deployments()}>
              <span>Rollups</span>
            </AppNavLinkTab>

            <AppNavLinkTab layoutPrefix="appNav" to={pb.nodes()}>
              <span>Nodes</span>
            </AppNavLinkTab>

            {!showHubLink && (
              <AppNavLinkTab layoutPrefix="appNav" to={pb.browseNetworks()}>
                <span>Browse rollups</span>
              </AppNavLinkTab>
            )}

            <AppNavLinkTab layoutPrefix="appNav" to={pb.organizationSettings()}>
              <span>Settings</span>
            </AppNavLinkTab>

            <div className="ml-auto flex gap-5">
              {showHubLink && (
                <AppNavExternalLinkTab href={ConduitTerminalUrl}>
                  <span>Hub</span>
                </AppNavExternalLinkTab>
              )}

              <AppNavExternalLinkTab href={MarketplaceUrl}>
                <span>Marketplace</span>
              </AppNavExternalLinkTab>
            </div>
          </>
        )}
      </nav>
    </div>
  );
}
