import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";

import type { UpdateUserRequest, UpdateUserResponse } from "../apis/api-types";
import { conduitAPI } from "../apis/conduit-api";
import { useOrganization } from "../context/hooks";
import { useAuthStore } from "../stores/auth-store";

export function useUserInvites() {
  return useQuery({
    queryKey: ["UserInvites"] as const,
    queryFn: async () => conduitAPI.loadUserInvites(),
  });
}

export const handleCustomerPortal = ({
  organization,
}: {
  organization: string | undefined;
}) =>
  queryOptions({
    enabled: !!organization,
    queryKey: ["CustomerPortal", organization] as const,
    async queryFn() {
      try {
        const resp = await conduitAPI.handleCustomerPortal({
          organization: organization!,
          network: undefined,
        });

        return resp;
      } catch {
        return {
          Url: undefined,
        };
      }
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

export function useCustomerPortal() {
  const organization = useOrganization();

  return useQuery(
    handleCustomerPortal({ organization: organization.organization }),
  );
}

export function useUpdateUser() {
  return useMutation<
    UpdateUserResponse,
    Error,
    UpdateUserRequest,
    { prevData: { name: string } }
  >({
    mutationKey: ["updateUser"] as const,
    mutationFn: (args) => conduitAPI.updateUser(args),
    onMutate: (args) => {
      const name = useAuthStore.getState().name;

      if (name) {
        useAuthStore.setState({ name: args.name });
      }

      return { prevData: { name } };
    },
    onError: (_err, _args, context) => {
      if (context?.prevData) {
        useAuthStore.setState({ name: context.prevData.name });
      }
    },
  });
}
